const getScreenQuadrant = (el: HTMLElement) => {
    const rect = el.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    const w = window.innerWidth;
    const h = window.innerHeight;
    return {
        x: centerX < w / 2 ? "left" : "right",
        y: centerY < h / 2 ? "top" : "bottom",
    };
};

const updateMenus = () => {
    const allMenus = document.querySelectorAll("ul[data-menu] > li");
    allMenus.forEach((menu) => {
        // if it doesn't have the data-events attribute, add it and register the event listener
        if (!menu.hasAttribute("data-events")) {
            menu.setAttribute("data-events", "true");
            menu.addEventListener("mouseenter", (e) => {
                const subMenus = menu.querySelectorAll("ul > li");
                subMenus.forEach((subMenu) => {
                    const subMenuQuadrant = getScreenQuadrant(subMenu as HTMLElement);
                    switch (subMenuQuadrant.x) {
                        case "left":
                            subMenu.setAttribute("data-expands-x", "right");
                            break;
                        case "right":
                            subMenu.setAttribute("data-expands-x", "left");
                            break;
                    }
                });
            });
        }
        const parentScreenQuadrant = getScreenQuadrant(menu as HTMLElement);
        switch (parentScreenQuadrant.y) {
            case "top":
                menu.setAttribute("data-expands-y", "bottom");
                break;
            case "bottom":
                menu.setAttribute("data-expands-y", "top");
                break;
        }
        switch (parentScreenQuadrant.x) {
            case "left":
                menu.setAttribute("data-expands-x", "right");
                break;
            case "right":
                menu.setAttribute("data-expands-x", "left");
                break;
        }
    });
};

const registerStubClicks = () => {
    document.addEventListener("click", (e) => {
        const target = e.target as HTMLElement;
        if (
            target.tagName === "A" &&
            target.getAttribute("href") === "#menu-stub"
        ) {
            e.preventDefault();
        }
        const closestLink = target.closest("a");
        if (closestLink && closestLink.getAttribute("href") === "#menu-stub") {
            e.preventDefault();
        }
    });
};

const register = () => {
    updateMenus();
    registerStubClicks();
    window.addEventListener("resize", updateMenus);
};

export { register };