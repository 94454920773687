<script lang="ts">
    import { slide } from "svelte/transition";

    export let list:any = [{
        question: 'Lorem Ipsum',
        answer: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>'
    }];

    const toggle = function(index) {
        list.forEach((item, currentIndex) => {
            if (currentIndex === index) {
                return;
            }

            item.isToggled = false;
        });

        list[index].isToggled = !list[index].isToggled;
    };
</script>
{#each list as item, index }
    <div class="list-item">
        <button on:click={() => toggle(index)} aria-expanded={item.isToggled}>
            <span class="icon-question">
                Q
            </span>
            <span class="question">
                {@html item.question}
            </span>
            <span class="icon-arrow">
                <i class="fa-solid fa-circle-chevron-right"></i>
            </span>
        </button>
        {#if item.isToggled}
            <div class="collapsible" transition:slide={{ duration: 300 }}>
                {@html item.answer}
            </div>
        {/if}
    </div>
{/each}